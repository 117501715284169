<template>
  <v-expansion-panel style="border-radius: 0!important;
   border-bottom: 1px solid rgba(79, 79, 79, 0.5); background-color: #FDFFFC"  v-on:click="clearSelected"  >
    <v-expansion-panel-header expand-icon="mdi-arrow-down-drop-circle-outline"  >
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center tw-justify-between tw-my-5" >
        <img :src="trip && trip.fleetForThisTrip && trip.fleetForThisTrip.vehicleImage
          ? trip.fleetForThisTrip.vehicleImage : camera" alt="vehicle image" class="vehicle " />
        <label class="normal-text tw-py-5 lg:tw-py-0 text-capitalize">{{trip && trip.fleetForThisTrip ? trip.fleetForThisTrip.vehicleClass : 'Nil'}}</label>
        <label class="bold-text tw-pb-5 lg:tw-pb-0 text-capitalize">{{trip ? convertToSentenceCase(trip.routeDepartureTerminalName +" "+
            trip.routeDepartureCity+ " - "+ trip.routeDestinationTerminalName+ " "+trip.routeDestinationCity) : 'Nil'}}</label>
        <label class="normal-text tw-pb-5 lg:tw-pb-0 ">{{trip ? getDateTime(trip.takeOffDate, trip.takeOffTime) : 'Nil'}}</label>
        <label class="normal-text tw-pb-5 lg:tw-pb-0 text-capitalize">{{availableSeats.length-1}}  seats available</label>
        <label class="normal-text tw-mr-20"> &#8358; {{trip ? formatNumber(trip.tripAmount) :'Nil'}}</label>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content >
      <div class="tw-flex tw-w-full tw-items-center tw-justify-start tw-flex-col">
        <div class="tw-w-full tw-flex tw-justify-end tw-py-3">
          <seat-timer/>
        </div>
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-flex-start">
          <vehicle-seats :trip="updatedTrip ? updatedTrip : trip" v-on="$listeners" />
          <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2">
            <p class="bold-text">Passenger Information</p>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(handlePosSubmit)">
                <div v-if="tripAndTravellerDetails.seats && tripAndTravellerDetails.seats.length">

                  <div v-for="(seat,index) in tripAndTravellerDetails.seats" :key="index">
                    <new-traveller-detail :traveller-detail="seat.travellerDetail" :index="index"/>
                  </div>
                </div>
                <div v-else class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center">
                  <img src="@/assets/empty-details.png" alt="empty details" class="empty-details-img">
                  <p class="bold-text">Select seat and fill passenger details</p>
                </div>

                <div class="tw-flex tw-justify-end tw-w-full tw-mt-5">
                  <div class="tw-hidden lg:tw-flex tw-w-full">
                    <base-button :color="color" button-text="Book" type="submit" :loading="loading"
                                 :disabled="!tripAndTravellerDetails.seats || !tripAndTravellerDetails.seats.length"></base-button>
                  </div>
                  <div class="tw-flex lg:tw-hidden tw-w-full tw-flex-col">
                    <label class="select-payment-option tw-my-3">Select Payment Option</label>
                    <v-radio-group v-model="selectedPaymentOption" class="select-payment-option" @change="handleSelectedPaymentOption(selectedPaymentOption)">
                      <v-radio v-for="(option, key) in paymentOptions"
                               :key="key" :value="option" :label="option"
                               :color="companyData.companyColor" class="tw-my-3"
                       >

                      </v-radio>
                    </v-radio-group>
                    <base-button width="100%" :height="20" button-text="Submit" type="submit" :color="color"
                                 :loading="loading"
                                 :disabled="!userSelectedPaymentOption || !tripAndTravellerDetails.seats">

                    </base-button>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
        <modal
            :dialog="showBookingSuccessful"
            @close="handleShowBookingSuccessful"
            :color="companyData.companyColor"
            title="Great!"
            description="Customer Booking was successful."
            icon="thumbs-up"
            additional-text="Thank you"
            section="print-ticket"
            @printTicket="handlePrintBooking"
        />

        <section slot="pdf-content" v-if="isPrinting">
          <booking-receipt  :booking="currentBooking" ref="customerReceipt" />

        </section>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import camera from '@/assets/camera.png'
import booked from '@/assets/booked.svg'
import selectedSeat from '@/assets/selected.svg'
import availableSeat from '@/assets/available.svg'
import steering from '@/assets/steering.svg'

import dayjs from "dayjs";
import VehicleSeats from "@/components/transportCompany/booking/VehicleSeats.vue";
import SeatTimer from "@/components/transportCompany/booking/SeatTimer";
import BaseButton from "../../reuseables/BaseButton.vue";
import NewTravellerDetail from "./NewTravellerDetail.vue";
import {mapGetters} from "vuex";
import Modal from "@/components/reuseables/Modal";
import {addBooking} from "@/services/api/APIService";
import BookingReceipt from "@/components/transportCompany/booking/BookingReceipt.vue";
import {getTripByTransportCompanyIdAndTripId} from "../../../services/api/APIService";

export default {
  name: "TripBusCard",
  components:  {NewTravellerDetail, BaseButton, SeatTimer, VehicleSeats,BookingReceipt,Modal},
  props:{
    color:{
      type:[String],
      default:"#004AAD"
    },
    trip:{
      type: Object
    }

  },
  data(){
    return{
      camera,
      bookedSeats: booked,
      selectedSeat,
      availableSeat,
      steering,
      selectedSeats:"",
      availableSeats:[],
      totalSeats:[],
      passengerDetail:{},
      staffData:{},
      showBookingSuccessful : false,
      loading : false,
      isPrinting: false,
      currentBooking:{},
      updatedTrip:null,
      paymentOptions:["Cash","Transfer","Card"],
      selectedPaymentOption:null
  }
  },
  watch:{
    trip:{
      handler : function (val){
        if (val && val.fleetForThisTrip){
          this.availableSeats = []
          val.fleetForThisTrip.seats.forEach(seat => {
            if (seat.status === 'AVAILABLE'){
              this.availableSeats.push(seat)
            }
            this.totalSeats = val.fleetForThisTrip.seats
          })
        }
      },
      immediate: true,
      deep : true
    }
  },
  computed:{
    ...mapGetters("tripAndTravellerDetails",["tripAndTravellerDetails","userSelectedPaymentOption"]),
    ...mapGetters("companyProfile",["companyData"])
  },
  methods:{
    handleSelectedPaymentOption(option){
      this.$store.dispatch("tripAndTravellerDetails/setUserSelectedPaymentOption", option)
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
    },
    convertToSentenceCase(sentence) {
      let words = sentence.split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(' ');
    },
    handleSelectedSeats(clickSeat){
     let tripDetail = {}
      tripDetail.tripId = this.trip.id
      tripDetail.seats = clickSeat
      tripDetail.tripAmount = this.trip.tripAmount
      this.$emit('getDetails',tripDetail)

    },
    // getAvailableSeats(){
    //   let seatsAvailable = []
    //   console.log(this.trip.fleetForThisTrip.seats)
    //   this.trip.fleetForThisTrip.seats.forEach(seat => {
    //     if (seat.status === 'AVAILABLE'){
    //       seatsAvailable.push(seat)
    //     }
    //   })
    //   return seatsAvailable.length -1;
    // },
    clearSelected(){
      sessionStorage.setItem("currentTripId",this.trip.id)
      this.$store.dispatch('seatsSelection/clearSeats')
    },
    getTime(date,time){
      return dayjs(date+time).format('LT')
    },
    getDateTime(date,time){
      return dayjs(date+time).format("lll")
    },
    handleShowBookingSuccessful(){
      if(this.showBookingSuccessful){
        sessionStorage.removeItem('oneWayBookingMinutesTimer')
        sessionStorage.removeItem('oneWayBookingShowTimer')
        sessionStorage.removeItem('currentSelectedSeats')
        this.$store.dispatch("seatsSelection/setOneWayBookingShowTimer",false)
        window.location.reload()
      }
      this.showBookingSuccessful = !this.showBookingSuccessful
    },
    handlePosSubmit(){
      this.loading = true
      let data = {}
      if (Object.keys(this.staffData).length){
        data.bookedBy = this.staffData.firstname
        data.bookedByTerminal = this.staffData.terminalAssignedName
      }
      else {
        data.bookedBy = "Head Office"
        data.bookedByTerminal = "Head Office"
      }
      data.bookingType = "Offline"
      data.selectedSeats = []
      data.travellerDetails=[]
      data.tripFare = (Number.parseFloat(this.tripAndTravellerDetails.tripFare) * this.tripAndTravellerDetails.seats.length)
      data.tripId = this.tripAndTravellerDetails.tripId
      data.selectedPaymentOption = this.selectedPaymentOption
      data.domain = window.location.hostname
      data.companyLogo= this.companyData.companyLogo
      data.companyEmail = this.companyData.companyEmail
      this.tripAndTravellerDetails.seats.forEach(seat =>{
        let singleSeat = {}
        singleSeat.seatNumber = seat.seatNumber
        singleSeat.selectedBy= seat.selectedBy
        data.selectedSeats.push(singleSeat)
        data.travellerDetails.push(seat.travellerDetail)})
      if (window.Android){
        window.Android.processBooking(JSON.stringify(data))
      }
      else {
        this.proceedToSubmit()
      }
    },
    proceedToSubmit(){
      this.loading = true
      let data = {}
      if (Object.keys(this.staffData).length){
        data.bookedBy = this.staffData.firstname
        data.bookedByTerminal = this.staffData.terminalAssignedName
      }
      else {
        data.bookedBy = "Head Office"
        data.bookedByTerminal = "Head Office"
      }
      data.bookingType = "Offline"
      data.selectedSeats = []
      data.travellerDetails=[]
      data.tripFare = (Number.parseFloat(this.tripAndTravellerDetails.tripFare) * this.tripAndTravellerDetails.seats.length) * 100
      data.tripId = this.tripAndTravellerDetails.tripId
      data.domain = window.location.hostname
      this.tripAndTravellerDetails.seats.forEach(seat =>{
        let singleSeat = {}
        singleSeat.seatNumber = seat.seatNumber
        singleSeat.selectedBy= seat.selectedBy
        data.selectedSeats.push(singleSeat)
        data.travellerDetails.push(seat.travellerDetail)

      })
       addBooking(data).then(async res => {
         this.loading = false
         sessionStorage.removeItem("primaryTraveller")
         sessionStorage.removeItem("passengerDetail")
         sessionStorage.removeItem("tripDetails")
         sessionStorage.removeItem("currentSelectedSeats")
         sessionStorage.removeItem("oneWaySeats")
         await this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails", {})
         res.data.tripFare = res.data.tripFare * 100
         this.currentBooking = res.data
         this.handleShowBookingSuccessful()
       }).catch((err) => {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });

    },
   async getCurrentTrip(){
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.tripId = this.trip.id
     await getTripByTransportCompanyIdAndTripId(data).then(res =>{
        this.updatedTrip = res.data
      }).catch((err) => {
        this.loading = false;
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    handlePrintBooking(){
      this.isPrinting = !this.isPrinting
      this.$refs.customerReceipt.print()
    },
    printTicket() {
      if (window.Android){
        let data = {}
        data.companyName = this.companyData.companyName
        data.companyLogo = this.companyData.companyLogo
        data.companyEmail = this.companyData.secondaryEmail
        data.companyPhoneNumber = this.companyData.firstContactPhoneNumber
        data.bookingRefId = this.currentBooking.bookingRefId
        data.selectedSeats = this.getSelectedSeats(this.currentBooking.selectedSeats)
        data.tripDate = this.currentBooking.tripDate
        data.tripTime = this.currentBooking.tripTime
        data.departure = this.currentBooking.departure
        data.arrival = this.currentBooking.arrival
        data.bookedBy = this.currentBooking.bookedBy
        data.bookedAt = this.currentBooking.createdAt
        data.totalAmount = this.currentBooking.tripFare / 10
        data.departureAddress = this.currentBooking.departureTerminalAddress
        data.customerFirstName = this.currentBooking.travellerDetails[0].firstName
        data.customerLastName = this.currentBooking.travellerDetails[0].lastName

        window.Android.printTicket(JSON.stringify(data));
      }
      else {
        this.handlePrintBooking()
      }
    },
    getSelectedSeats(seats){
      let returnSeats = ""
      seats.forEach((seat,index) =>{
        returnSeats += seat.seatNumber
        if (index+1 !== seats.length){
          returnSeats += ", "
        }
      })
      return returnSeats
    },
  }
}
</script>

<style scoped lang="scss">
.vehicle{
  width: 120px;
  height: 77px;
  object-fit: fill;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.bold-text{
  font-family: Inter,sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
  color: #4F4F4F;

}
.normal-text{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px;
  color: #4F4F4F;
}

.seat-panel{
  background: #FDFFFC;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  min-width: 250px;
  width: max-content;
  height: max-content;

  @media screen  and (max-width: 768px){
    width: 100%;
  }
}
.column{
  display: flex;
  flex-direction: column;

}
.empty-details-img{
  width: 400px;
  height: 300px;
  object-fit: contain;
}
.select-payment-option{
  font-family: Raleway, sans-serif !important;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 17px;
  color: #4F4F4F;
}
</style>